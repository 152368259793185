// Vendor
// ---------------------------------

@import "vendor/normalize";
// Swiper 7.4.1
// @import "vendor/swiper";
@import "vendor/sal";

// Global
// ---------------------------------

@import "variables";
@import "mixins";
@import "functions";
@import "keyframes";
@import "typography";
@import "global/fonts";
@import "global/reboot";
@import "global/utils";
@import "global/container";

// Blocks
// ---------------------------------

@import "blocks/header";
@import "blocks/main-nav";
@import "blocks/footer";
@import "blocks/modal";
@import "blocks/btn";
@import "blocks/custom-input";
@import "blocks/custom-toggle";
@import "blocks/custom-select";
@import "blocks/link";

@import "blocks/intro";
@import "blocks/menu";
@import "blocks/page-section";
@import "blocks/cloud-services";
@import "blocks/items-list";
@import "blocks/lists-with-desc";
@import "blocks/feedback";
@import "blocks/about";


// ---------------------------------

// ❗❗❗ запрещено использовать изображения в css, касается как jpg \ png, так и svg

// ❗ обязательно используйте @media для отключения ховеров на тач устройствах
// но не используем для текстовых полей ( input, textarea )
/* @media (hover: hover), screen and (min-width: 0\0) {
  &:hover,
  &:focus {
    color: $color-default-white;
  }
} */

// адаптив пишем внутри каждого класса (смотрим container.scss)

// для фикса проблем с vh на iOS в сборке подключен скрипт
// используя vh на проекте задавайте их также как в примере в utils.scss

// для любых transition обязательно указывайте transition-property
// transition: $trans-default ❌ ---> transition: color $trans-default ✅
