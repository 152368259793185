.lists-with-desc {
  $root: &;

  display: grid;
  grid-template-columns: repeat(2, 50%);
  position: relative;
  z-index: 1;

  @include vp-1023 {
    grid-template-columns: 100%;
  }

  &::after {
    @include horizontal-line(0, 0, $color-silver);
    bottom: 0;
  }

  &:last-child {
    &::after {
      display: none;
    }
  }

  &__text {
    padding: 40px;
    position: relative;

    &::before {
      @include vertical-line(0, 0, $color-silver);
      right: 0;
    }

    @include vp-1279 {
      padding: 25px;
    }

    @include vp-1023 {
      &::before {
        top: auto;
        left: 0;
        width: auto;
        height: 1px;
      }
    }

    @include vp-767 {
      padding: 20px;
    }
  }

  &__icon {
    width: 32px;
    height: 32px;
    margin-bottom: 40px;

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }

    @include vp-1279 {
      margin-bottom: 25px;
    }

    @include vp-767 {
      margin-bottom: 20px;
    }
  }

  &__title {
    margin: 0 0 40px;

    @include vp-1279 {
      margin-bottom: 25px;
    }

    @include vp-767 {
      margin-bottom: 18px;
    }
  }

  &__desc {
    margin-bottom: 40px;

    p {
      margin: 0 0 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @include vp-1279 {
      margin-bottom: 25px;
    }

    @include vp-767 {
      margin-bottom: 18px;
    }
  }

  &__btn {
    @include vp-767 {
      display: flex;
      width: 100%;
    }
  }

  .items-list__item {
    @include vp-767 {
      padding-right: 30px;
    }
  }

  &--items-left {
    #{$root}__text {
      order: 2;

      &::before {
        display: none;
      }

      @include vp-1023 {
        order: 0;

        &::before {
          display: block;
        }
      }
    }

    #{$root}__items {
      position: relative;
      z-index: 1;

      &::before {
        @include vertical-line(0, 0, $color-silver);
        right: 0;
      }
    }
  }
}
