.h2,
.h3,
.text-large,
h2,
h3 {
  font-family: $ff-proto-grotesk;
  font-weight: 300;
  font-size: 80px;
  line-height: 1;

  @include vp-1279 {
    font-size: 72px;
  }

  @include vp-1023 {
    font-size: 56px;
  }

  @include vp-767 {
    font-size: 40px;
  }
}

.h3,
h3 {
  font-size: 48px;
  line-height: 1.4;

  @include vp-1279 {
    font-size: 44px;
  }

  @include vp-1023 {
    font-size: 36px;
  }

  @include vp-767 {
    font-size: 30px;
  }
}

.text-large {
  font-size: 32px;
  line-height: 1.4;

  @include vp-1279 {
    font-size: 28px;
  }

  @include vp-1023 {
    font-size: 24px;
  }

  @include vp-767 {
    font-size: 20px;
  }
}

@mixin text-regular {
  font-size: 20px;
  line-height: 1.4;

  @include vp-1279 {
    font-size: 18px;
  }

  @include vp-1023 {
    font-size: 16px;
  }
}

.text-regular {
  @include text-regular();
}
