// @font-face {
//   font-style: normal;
//   font-weight: 400;
//   font-family: "Rouble";

//   font-display: swap;
//   src:
//     url("../fonts/rouble.woff2") format("woff2"),
//     url("../fonts/rouble.woff") format("woff");
// }


@font-face {
  font-family: 'Roboto Mono';
  src: url('../fonts/roboto-mono-regular.woff2') format('woff2'),
      url('../fonts/roboto-mono-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proto Grotesk';
  src: url('../fonts/proto-grotesk-light.woff2') format('woff2'),
       url('../fonts/proto-grotesk-light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

