.btn {
  @include text-regular;

  display: inline-flex;
  vertical-align: top;
  align-items: center;
  justify-content: center;

  padding: 10px 20px;

  color: $color-default-white;

  background-color: $color-niagara;
  border: none;
  cursor: pointer;
  border-radius: 24px;

  transition: opacity $trans-default;

  outline: none;

  svg {
    width: 24px;
    height: 24px;
    margin-left: 10px;
    flex-shrink: 0;
  }

  &:disabled {
    opacity: 0.5;

    pointer-events: none;
  }

  &__inner {
    @include doubleText;
  }

  &--red {
    background-color: $color-torch-red;
  }

  &--transparent {
    background: none;
    padding: 0;
  }

  &--icon-left {
    svg {
      order: -1;
      margin-left: 0;
      margin-right: 20px;
    }
  }

  &--scroll-down,
  &--scroll-down-transparent {
    padding: 20px 10px;

    svg {
      margin: 0;
    }

    transition: opacity $trans-default;


    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        opacity: 0.6;
      }
    }
  }

  &--scroll-down-transparent {
    background: none;
    color: $color-niagara;
  }
}
