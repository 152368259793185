.container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 40px;

  position: relative;
  z-index: 1;

  &::before,
  &::after {
    @include vertical-line(0, 0, $color-silver);
    @include line-vertical-anim;

    left: 40px;
  }

  &::after {
    left: auto;
    right: 40px;
  }

  @include vp-1279 {
    padding: 0 25px;

    &::before {
      left: 25px;
    }

    &::after {
      right: 25px;
    }
  }

  @include vp-767 {
    padding: 0 20px;

    &::before {
      left: 20px;
    }

    &::after {
      right: 20px;
    }
  }
}
