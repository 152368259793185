.link {
  cursor: pointer;
  transition: opacity $trans-default;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      opacity: 0.6;
    }
  }
}
