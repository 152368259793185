.custom-input,
.custom-textarea {
  position: relative;

  display: flex;
  flex-direction: column;

  label {
    display: flex;
    flex-direction: column;
    width: 100%;

    input,
    textarea {
      padding: 24px 28px;

      border: 2px solid $color-default-white;
      outline: none;

      transition: $trans-default;
      transition-property: background-color, border-color, color;

      background: $color-transparent;
      color: $color-default-white;

      &::placeholder {
        opacity: 1;
        color: rgba($color-default-white, 0.5);
      }

      &:hover {
        border-color: rgba($color-default-white, 0.5);
      }

      &:focus {
        background-color: $color-honeydew;
        color: $color-sherpa-blue;

        &::placeholder {
          color: rgba($color-sherpa-blue, 0.5);
        }
      }

      @include vp-1023 {
        padding: 20px;
      }

      @include vp-767 {
        padding: 15px;
      }
    }
  }

  &__label {
    margin-bottom: 8px;
  }

  &__error {
    position: absolute;
    left: 0;
    bottom: -28px;

    color: $color-flamingo;

    opacity: 0;

    transition: opacity $trans-default;

    font-size: 14px;
    line-height: 1.4;

    @include vp-1279 {
      display: none;
      position: static;
      margin-top: 8px;
    }
  }

  &.is-invalid {
    input {
      border-color: $color-flamingo;

      &:focus,
      &:hover {
        border-color: rgba($color-flamingo, 0.5);
      }
    }

    .custom-input__error {
      display: block;
      opacity: 1;
    }
  }

  &.is-valid {
    input {
      border-color: $color-medium-turquoise;

      &:focus,
      &:hover {
        border-color: rgba($color-medium-turquoise, 0.5);
      }
    }
  }
}
