.footer {
  background: $color-sherpa-blue;
  color: $color-default-white;
  position: relative;
  z-index: 1;

  &::before {
    @include horizontal-line(40px, 40px, $color-niagara);
    @include line-horizontal-anim;

    top: 0;

    max-width: 1360px;
    margin: 0 auto;

    @include vp-1279 {
      left: 25px;
      right: 25px;
    }

    @include vp-767 {
      left: 20px;
      right: 20px;
    }
  }

  &.sal-animate {
    &::before {
      animation: lineHorizontalAnim 1.5s forwards 0.5s;
    }
  }

  .container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    &::before,
    &::after {
      background-color: $color-niagara;
    }

    @include vp-1023 {
      grid-template-columns: 100%;
    }
  }

  &__logo {
    img {
      width: 254px;
      height: 64px;
    }

    @include vp-767 {
      img {
        margin: 0 auto;
      }
    }
  }

  &__block {
    padding: 40px;
    position: relative;
    z-index: 1;

    &::before {
      @include vertical-line(0, 0, $color-niagara);
      @include line-vertical-anim;
      right: 0;
    }

    @include vp-1279 {
      padding: 25px;
    }

    @include vp-1023 {
      &::before {
        left: 0;
        width: auto;
        height: 1px;
        top: auto;
      }
    }

    @include vp-767 {
      padding: 20px;
    }
  }

  &__links {
    margin: 0;
    list-style: none;
  }

  &__links-item {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    @include vp-1279 {
      margin-bottom: 25px;
    }

    @include vp-767 {
      margin-bottom: 20px;
    }
  }

  &__name,
  &__address {
    margin: 0 0 40px;

    &:last-child {
      margin-bottom: 0;
    }

    @include vp-1279 {
      margin-bottom: 25px;
    }

    @include vp-767 {
      margin-bottom: 20px;
    }
  }

  &__contacts-link {
    display: block;
  }

  &__requisites {

    grid-column: span 3;

    .container {
      padding: 40px;
      display: block;

      &::before {
        right: 40px;
        width: auto;
        bottom: auto;
        height: 1px;
        @include line-horizontal-anim;
      }

      &::after {
        display: none;
      }

      @include vp-1279 {
        padding: 25px;

        &::before {
          right: 25px;
        }
      }

      @include vp-1023 {
        &::before {
          display: none;
        }
      }

      @include vp-767 {
        padding: 20px 40px;
      }
    }
  }
}
