.page-section {
  $root: &;

  background: $color-solitude;
  position: relative;
  z-index: 1;

  &__top {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    position: relative;
    z-index: 1;

    &::before,
    &::after {
      @include horizontal-line(0, 0, $color-silver);
      @include line-horizontal-anim;
      top: 0;
    }

    &::after {
      top: auto;
      bottom: 0;
    }

    @include vp-1023 {
      grid-template-columns: 100%;
    }
  }

  &__title {
    margin: 0 0 40px;

    @include vp-1279 {
      margin-bottom: 25px;
    }

    @include vp-767 {
      margin-bottom: 20px;
    }
  }

  &__text {
    padding: 40px;
    position: relative;
    grid-column: span 2;

    &::before {
      @include vertical-line(0, 0, $color-silver);
      @include line-vertical-anim;
      transform-origin: center top;

      right: 0;
    }

    &:last-child {
      &::before {
        right: auto;
        left: 0;
      }
    }

    @include vp-1279 {
      padding: 25px;
    }

    @include vp-1023 {
      padding: 20px;

      &::before {
        left: 0;
        width: auto;
        height: 1px;
      }

      &:last-child {
        &::before {
          right: 0;
        }
      }
    }
  }

  &__desc {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    p {
      margin: 0 0 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @include vp-1279 {
      margin-bottom: 25px;
    }

    @include vp-767 {
      margin-bottom: 20px;
    }
  }

  &__image {
    padding: 40px 20px;

    display: flex;
    align-items: center;
    justify-content: center;

    min-height: 480px;
    position: relative;

    svg {
      display: block;
      max-width: 100%;
      height: auto;
    }

    .rotate {
      transform-box: fill-box;
      transform-origin: center center;
      animation: rotate 5s infinite linear;
    }

    @include vp-1023 {
      min-height: auto;
      order: -1;
    }

    @include vp-767 {
      min-height: 275px;
      padding: 10px 20px;

      img,
      svg {
        max-width: 240px;
        max-height: 250px;
      }
    }
  }

  &__bottom {
    background: $color-default-white;

    .container {
      padding: 40px 80px;
    }

    @include vp-1279 {
      .container {
        padding: 25px 50px;
      }
    }

    @include vp-767 {
      .container {
        padding: 20px 40px;
      }
    }
  }

  &__btn {
    @include vp-767 {
      display: flex;
      width: 100%;
    }
  }


  &--white {
    background: $color-default-white;

    #{$root}__bottom {
      background: $color-solitude;
    }
  }

  &--green-bottom {
    #{$root}__bottom {
      background: $color-sherpa-blue;

      .container {
        &::before,
        &::after {
          background-color: $color-niagara;
        }
      }
    }
  }
}
