.intro {
  background: $color-niagara;
  color: $color-default-white;
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 40px;
  animation: fadeIn 1.5s forwards;

  @include vp-767 {
    padding-top: 20px;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    width: 2991px;
    height: 2991px;
    top: 50%;
    left: 50%;
    transform: translate(-60.6%,-47.4%);
    background: url("../img/svg/intro-waves.svg") no-repeat;
    background-size: 100% 100%;
    animation: introWaves 1.5s forwards;

    @include vp-1023 {
      transform: translate(-60.6%,-46.4%);
      animation-name: introWavesTablet;
    }

    @include vp-767 {
      width: 2430px;
      height: 2430px;
      transform: translate(-51%,-46%);
      animation-name: introWavesMobile;
    }
  }

  .container {
    display: grid;
    grid-template-columns: 33% 67%;
    grid-template-rows: auto 1fr auto;

    &::before,
    &::after {
      background-color: $color-default-white;
      transform-origin: center bottom;
    }

    @include vp-1023 {
      grid-template-columns: 35% 65%;
    }

    @include vp-767 {
      grid-template-columns: 100%;
      padding: 20px 40px 0;
    }
  }

  &__block {
    padding: 40px;

    @include vp-1279 {
      padding: 25px;
    }

    @include vp-1023 {
      padding: 25px 15px;
    }

    @include vp-767 {
      padding: 0;
    }
  }

  &__logo {
    padding-bottom: 24px;
    margin-bottom: 130px;


    &::before {
      @include vertical-line;
      @include line-vertical-anim;

      left: calc(40px + ((100% - 80px) * 0.33));
    }

    &::after {
      @include horizontal-line(40px, 40px);
      @include line-horizontal-anim;
      top: 0;
    }

    img {
      width: 254px;
      height: 64px;
      @include slide-down-large;
    }

    @include vp-1279 {
      &::before {
        left: calc(25px + ((100% - 50px) * 0.33));
      }

      &::after {
        left: 25px;
        right: 25px;
      }
    }

    @include vp-1023 {
      &::before {
        left: calc(25px + ((100% - 50px) * 0.35));
      }
    }

    @include vp-767 {
      margin-bottom: 20px;

      &::before {
        display: none;
      }

      &::after {
        left: 20px;
        right: 20px;
      }
    }
  }

  &__btn {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    margin-bottom: 130px;

    &::after {
      @include horizontal-line;
      @include line-horizontal-anim;

      bottom: 0;
    }

    .btn {
      @include slide-down-large;
    }

    @include vp-767 {
      margin-bottom: 0;

      .btn {
        display: flex;
        width: 100%;
      }

      &::after {
        display: none;
      }
    }
  }

  &__menu {
    grid-row: span 2;

    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @include clip-down;

    @include vp-767 {
      margin: 110px 0;
    }
  }

  &__phone {
    position: relative;

    &::after {
      @include horizontal-line;
      @include line-horizontal-anim;
      top: 0;
    }

    @include vp-767 {
      margin: 0 -20px;
      padding: 20px;
    }
  }

  &__phone-link {
    @include clip-right;
  }

  &__text {
    @include clip-right;

    p {
      margin: 0 0 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @include vp-767 {
      display: none;
    }
  }

  &__bottom {
    background: $color-solitude;

    .container {
      align-items: center;

      &::before,
      &::after {
        background-color: $color-silver;
      }

      @include vp-767 {
        display: flex;
        padding: 20px 40px;
      }
    }
  }

  &__notice {
    color: $color-black-pearl;
    margin: 0;
    text-align: right;

    @include vp-767 {
      margin-left: auto;
    }
  }
}
