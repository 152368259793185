.items-list {
  $root: &;

  margin: 0;
  padding: 0;
  list-style: none;

  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @include vp-767 {
    display: block;
  }

  &__item {
    position: relative;
    z-index: 1;
    padding: 40px;

    &::before {
      @include vertical-line(0, 0, $color-silver);
      @include line-vertical-anim;
      transform-origin: center center;
      right: 0;
    }

    &::after {
      @include horizontal-line(0, 0, $color-silver);
      @include line-horizontal-anim;
      bottom: 0;
    }

    &:last-child {
      &::before {
        display: none;
      }
    }

    &:nth-child(3n) {
      &::before {
        display: none;
      }
    }

    &:nth-last-child(1),
    &:nth-last-child(2) {
      &::after {
        display: none;
      }
    }

    @include vp-1279 {
      padding: 25px;
    }

    @include vp-767 {
      padding: 20px;

      &::before {
        display: none;
      }

      &:nth-last-child(2)::after {
        display: block;
      }
    }
  }

  &__text {
    margin: 0;
  }

  &__icon {
    width: 32px;
    height: 32px;
    margin-bottom: 40px;

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }

    @include vp-1279 {
      margin-bottom: 25px;
    }

    @include vp-767 {
      margin-bottom: 20px;
    }
  }

  &--cols-2 {
    grid-template-columns: repeat(2, 1fr);

    #{$root}__item {
      &:nth-child(n) {
        &::before {
          display: block;
        }
      }

      &:nth-child(2n) {
        &::before {
          display: none;
        }
      }
    }
  }

  &--cols-4 {
    grid-template-columns: repeat(4, 1fr);

    @include vp-1023 {
      grid-template-columns: repeat(2, 1fr);
    }

    #{$root}__item {
      &::after {
        display: none;
      }

      &:nth-child(n) {
        &::before {
          display: block;
        }
      }

      &:nth-child(4n) {
        &::before {
          display: none;
        }
      }

      @include vp-1023 {
        &::after {
          display: block;
        }

        &:nth-last-child(1),
        &:nth-last-child(2) {
          &::after {
            display: none;
          }
        }
      }
    }


  }

  &--numeric {
    counter-reset: numeric-list;

    #{$root}__item {
      counter-increment: numeric-list;
    }

    #{$root}__text {
      &::before {
        content: counter(numeric-list, decimal-leading-zero);
        color: $color-niagara;
        display: block;
        margin-bottom: 40px;
      }

      @include vp-1279 {
        &::before {
          margin-bottom: 25px;
        }
      }

      @include vp-767 {
        &::before {
          margin-bottom: 29px;
        }
      }
    }
  }
}
