.feedback {
  $root: &;

  background: $color-sherpa-blue;
  color: $color-default-white;

  position: relative;

  &::after {
    @include horizontal-line(40px, 40px, $color-niagara);
    @include line-horizontal-anim;

    top: 0;
    max-width: 1360px;
    margin: 0 auto;


    @include vp-1279 {
      left: 25px;
      right: 25px;
    }

    @include vp-767 {
      left: 20px;
      right: 20px;
    }
  }

  &.sal-animate {
    &::after {
      animation: lineHorizontalAnim 1.5s forwards 0.5s;
    }
  }

  .container {
    display: grid;
    grid-template-columns: repeat(2, 50%);

    &::before,
    &::after {
      background-color: $color-niagara;
    }

    @include vp-767 {
      grid-template-columns: 100%;
    }
  }

  &__text {
    padding: 40px;
    position: relative;

    &::before {
      @include vertical-line(0, 0, $color-niagara);
      @include line-vertical-anim;

      right: 0;
    }

    @include vp-1279 {
      padding: 25px;
    }

    @include vp-767 {
      padding: 20px;
    }
  }

  &__title {
    margin: 0 0 40px;

    @include vp-1279 {
      margin-bottom: 25px;
    }

    @include vp-767 {
      margin-bottom: 20px;
    }
  }

  &__desc {
    max-width: 365px;

    p {
      margin: 0 0 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__form {
    padding: 40px;

    @include vp-1279 {
      padding: 25px;
    }

    @include vp-767 {
      padding: 20px;
    }
  }

  &__fields,
  &__field,
  &__btn {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    @include vp-1279 {
      margin-bottom: 25px;
    }

    @include vp-767 {
      margin-bottom: 20px;
    }
  }

  &__btn {
    @include vp-767 {
      display: flex;
      width: 100%;
    }
  }

  &__personal-data {
    margin: 0;
  }

  &--success {
    #{$root}__form {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
