@mixin retina {
  @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
    @content;
  }
}

// Desktop first

@mixin vp-1919 {
  @media (max-width: $vp-1920 - 1) {
    @content;
  }
}

@mixin vp-1439 {
  @media (max-width: $vp-1440 - 1) {
    @content;
  }
}

@mixin vp-1279 {
  @media (max-width: $vp-1280 - 1) {
    @content;
  }
}

@mixin vp-1023 {
  @media (max-width: $vp-1024 - 1) {
    @content;
  }
}

@mixin vp-767 {
  @media (max-width: $vp-768 - 1) {
    @content;
  }
}

@mixin vp-374 {
  @media (max-width: $vp-375 - 1) {
    @content;
  }
}

// Mobile first

// @mixin vp-375 {
//   @media (min-width: $vp-375) {
//     @content;
//   }
// }

// @mixin vp-768 {
//   @media (min-width: $vp-768) {
//     @content;
//   }
// }

// @mixin vp-1024 {
//   @media (min-width: $vp-1024) {
//     @content;
//   }
// }

// @mixin vp-1280 {
//   @media (min-width: $vp-1280) {
//     @content;
//   }
// }

// @mixin vp-1440 {
//   @media (min-width: $vp-1440) {
//     @content;
//   }
// }

// @mixin vp-1920 {
//   @media (min-width: $vp-1920) {
//     @content;
//   }
// }

// ---------------------------------

// не нужно добавлять свои миксины для ресета списков, обнуления кнопок и прочие
// этим вы усложняете работу команды, проверку вашего кода и будущую поддержку проекта

@mixin vertical-line($start: 0, $end: 0, $color: $color-default-white, $width: 1px) {
  content: "";
  position: absolute;
  z-index: 1;
  top: $start;
  bottom: $end;
  background: $color;
  width: $width;
}

@mixin horizontal-line($start: 0, $end: 0, $color: $color-default-white, $width: 1px) {
  content: "";
  position: absolute;
  z-index: 1;
  left: $start;
  right: $end;
  background: $color;
  height: $width;
}

@mixin slide-down-large {
  transform: translateY(-200px);

  .sal-animate & {
    animation: slideDownLarge 1s forwards 1.5s;
  }
}

@mixin clip-right {
  clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);

  .sal-animate & {
    animation: clipRight 1.5s forwards 1.5s;
  }
}

@mixin clip-down {
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);

  .sal-animate & {
    animation: clipDown 1.5s forwards 1.5s;
  }
}

@mixin line-vertical-anim {
  transform-origin: center top;
  transform: scale(1, 0);

  .sal-animate & {
    animation: lineVerticalAnim 1.5s forwards 0.5s;
  }
}

@mixin line-horizontal-anim {
  transform-origin: left center;
  transform: scale(0, 1);

  .sal-animate & {
    animation: lineHorizontalAnim 1.5s forwards 0.5s;
  }
}

@mixin doubleText {
  position: relative;
  z-index: 1;
  overflow: hidden;

  span {
    display: block;
    transition: transform $trans-default;
  }

  &::after {
    content: attr(data-text);
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateY(100%);
    transition: transform $trans-default;
    pointer-events: none;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      span {
        transform: translateY(-100%);
      }

      &::after {
        transform: translateY(0);
      }
    }
  }
}
