// используйте для скрытия нежелательных анимаций-скачков при поворотах экрана
@keyframes disableBreakpointAnimation {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  100% {
    visibility: hidden;
    opacity: 0;
  }
}

@keyframes introWaves {
  0% {
    transform: translate(-60.6%,-37.4%);
  }

  100% {
    transform: translate(-60.6%,-47.4%);
  }
}

@keyframes introWavesTablet {
  0% {
    transform: translate(-60.6%,-36.4%);
  }

  100% {
    transform: translate(-60.6%,-46.4%);
  }
}

@keyframes introWavesMobile {
  0% {
    transform: translate(-51%,-36%);
  }

  100% {
    transform: translate(-51%,-46%);
  }
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slideDownLarge {
  0% {
    transform: translateY(-200px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes clipRight {
  0% {
    clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  }

  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

@keyframes clipDown {
  0% {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  }

  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

@keyframes lineVerticalAnim {
  0% {
    transform: scale(1, 0);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes lineHorizontalAnim {
  0% {
    transform: scale(0, 1);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
