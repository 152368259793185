/**
 * Settings
 */

$sal-animation-duration: 1.5s !default;
$sal-animation-delay: 0s !default;
$sal-slide-offset: 100px !default;
$sal-zoom-in-scale: 0.5 !default;
$sal-zoom-out-scale: 1.1 !default;
$sal-flip-rotate: 91deg !default;

/**
* Easings
*/

/**
* Core
*/

[data-sal] {
  transition-duration: $sal-animation-duration;
  transition-delay: $sal-animation-delay;
  transition-duration: var(--sal-duration, $sal-animation-duration);
  transition-delay: var(--sal-delay, $sal-animation-delay);
  transition-timing-function: ease;
}

@for $i from 4 through 40 {
  [data-sal][data-sal-duration='#{$i * 50}'] {
    transition-duration: #{$i * 0.05}s;
  }
}

@for $i from 1 through 20 {
  [data-sal][data-sal-delay='#{$i * 50}'] {
    transition-delay: #{$i * 0.05}s;
  }
}

/**
* Animations
*/

// Fade
[data-sal|='fade'] {
  opacity: 0;
  transition-property: opacity;
}

[data-sal|='fade'].sal-animate,
body.sal-disabled [data-sal|='fade'] {
  opacity: 1;
}

// Slide
[data-sal|='slide'] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-sal='slide-up'] {
  transform: translateY($sal-slide-offset);
}

[data-sal='slide-down'] {
  transform: translateY(-$sal-slide-offset);
}

[data-sal='slide-left'] {
  transform: translateX($sal-slide-offset);
}

[data-sal='slide-right'] {
  transform: translateX(-$sal-slide-offset);
}

[data-sal='slide-up-large'] {
  transform: translateY(1.5rem);
}

[data-sal|='slide'].sal-animate,
body.sal-disabled [data-sal|='slide'] {
  opacity: 1;
  transform: none;
}
