.about {
  background: $color-sherpa-blue;
  color: $color-default-white;

  .container {
    display: grid;
    grid-template-columns: repeat(2, 50%);

    &::before,
    &::after {
      background-color: $color-niagara;
    }

    @include vp-1023 {
      grid-template-columns: 100%;
    }
  }

  &__list {
    position: relative;

    &::before {
      @include vertical-line(0, 0, $color-niagara);
      @include line-vertical-anim;
      right: 0;
    }

    @include vp-1023 {
      &::before {
        width: auto;
        left: 0;
        bottom: auto;
        height: 1px;
      }
    }
  }

  &__text {
    padding: 40px;
    display: flex;
    flex-direction: column;

    &::before {
      @include horizontal-line(40px, 40px, $color-niagara);
      @include line-horizontal-anim;
      top: 0;
    }

    @include vp-1279 {
      padding: 25px;

      &::before {
        left: 25px;
        right: 25px;
      }
    }

    @include vp-1023 {
      order: -1;
    }

    @include vp-767 {
      padding: 20px;
    }
  }

  &__title {
    margin: 0 0 40px;
    color: $color-niagara;
    font-family: $ff-roboto-mono;
    flex-shrink: 0;

    @include vp-1279 {
      margin-bottom: 25px;
    }

    @include vp-767 {
      margin-bottom: 25px;
    }
  }

  &__desc {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
      margin: 0 0 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__list {
    .items-list__item {
      &::before,
      &::after {
        background: $color-niagara;
      }
    }
  }

  &__image {
    padding: 80px 40px;

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @include vp-1279 {
      padding: 50px 25px;
    }

    @include vp-1023 {
      order: -2;
      padding: 25px;

      img {
        max-width: 300px;
      }

      &::before {
        @include horizontal-line(0, 0, $color-niagara);
        bottom: 0;
      }
    }

    @include vp-767 {
      padding: 20px;

      img {
        max-width: 275px;
      }
    }
  }

}
